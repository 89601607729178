import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getDomain, withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import getUrl from 'lib/utils/getUrl';
import { isNotNull, isNotUndefined } from 'typesafe-utils';
import { Link, type LinkProps } from './Link';

export interface StandaloneLinkProps extends StandaloneComponentProps {
  href?: LinkProps['href'];
  target?: LinkProps['target'];
  content?: React.ReactNode;
  options?: Omit<LinkProps, 'href' | 'target'>;
}

export const StandaloneLink: StandaloneComponent<StandaloneLinkProps> = ({ content, href, options, ...props }) => {
  const domain = getDomain(false);
  const url = getUrl(href) || getUrl(href, domain);
  const isHrefValid = isNotUndefined(href) && isNotNull(url);
  const isHrefExternal = isHrefValid && url.origin !== domain;
  const resolvedHref = isHrefValid ? (isHrefExternal ? url?.href?.replace(/(\/)(?=\?|$)/, '') : url?.pathname + url?.search) : undefined;

  return (
    <Link href={resolvedHref} {...withSafeInnerHTML(content)} {...options} {...props}>
      {withNonHTMLChildren(content)}
    </Link>
  );
};
