/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneSpinner as ResolvedStandaloneSpinner } from 'base/components/standalone/Spinner';

export const Spinner = ResolvedStandaloneSpinner;

export type SpinnerProps = PropsFromComponent<typeof Spinner>;

export const SpinnerWith = (extras: DynamicStandaloneExtras): typeof Spinner => {
    return function Spinner(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Spinner');
        return <ResolvedStandaloneSpinner {...mergeProps({ options: { theme } }, props)} />;
    }
}