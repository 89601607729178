import { ComponentProps, ElementKey, ProxyProp } from '@/types/component';
import { MergeProps } from '@/types/utils';
import { isIntrinsicElement } from '@/utils/isIntrinsicElement';
import { merge, mergeProps } from '@/utils/merge';
import { ClassNameOptions, UseClassName } from 'lib/contexts/ComponentClassNameFactory';
import { withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import React, { forwardRef } from 'react';

type Styles = {
  key?: string;
  useClassName?: UseClassName;
};

export type GenericComponentProps<Element extends ElementKey> = {
  name?: string;
  styles?: Styles;
  options?: ClassNameOptions<any>;
} & ProxyProp<Element>;

type GenericFunctionComponentProps<Element extends ElementKey, Extras = never> = MergeProps<
  ComponentProps<Element>,
  Extras
> &
  React.PropsWithChildren &
  ProxyProp<Element>;

type HTMLElementFromKey<Element extends ElementKey> = Element extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[Element]
  : never;

export interface GenericFunctionComponent<Element extends ElementKey, FactoryExtras = never> {
  <Extras>(
    props: GenericFunctionComponentProps<Element, FactoryExtras & Extras> &
      React.RefAttributes<HTMLElementFromKey<Element>>
  ): React.ReactNode;
  displayName?: React.FunctionComponent['displayName'];
}

export type GenericComponent = <Element extends ElementKey, FactoryExtras>(
  props: GenericComponentProps<Element>
) => GenericFunctionComponent<Element, FactoryExtras>;

export const GenericComponentFactory = <FactoryExtras extends object>(
  common: Required<Pick<Styles, 'useClassName'>>
) => {
  return <Element extends ElementKey = 'div'>({ name, ...props }: GenericComponentProps<Element>) => {
    const mergedProps = merge({ styles: { useClassName: common.useClassName }, name }, props);
    const Component = GenericComponent<Element, FactoryExtras>(mergedProps);

    return Component;
  };
};

export const GenericComponent: GenericComponent = ({ as, name, styles, options }) => {
  // eslint-disable-next-line react/display-name
  const Component: ReturnType<GenericComponent> = forwardRef(({ children, ...props }, ref) => {
    const Tag = (props.as || as || 'div') as ElementKey;

    const isStringTag = typeof Tag === 'string';
    const isCustomTag = isStringTag && !isIntrinsicElement(Tag);

    const className = styles?.useClassName?.(
      styles.key,
      props,
      merge(
        {
          fallbacks: {
            size: 'variant',
            colors: 'variant',
          },
        },
        options
      )
    );

    return (
      <Tag
        {...withSafeInnerHTML(children)}
        {...mergeProps({ [isCustomTag ? 'class' : 'className']: className }, props)}
        ref={ref}
      >
        {withNonHTMLChildren(children)}
      </Tag>
    );
  });

  if (!Component.displayName) {
    const inherited = (as as any)?.render?.displayName || (as as any)?.displayName;
    const fallback = typeof as === 'string' ? as : 'GenericComponent';

    Component.displayName = name || inherited || fallback;
  }

  return Component;
};
