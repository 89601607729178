/**
 *  AlltId - [All]er [T]raversable [Id]entification - Wrapper
 *  AlltId wrapper Library get the id.min.js file from s3 bucket
 *  and append to lab4 pages.
 *
 *  Feature: To include a JS file from a cdn repo and run function from the JS with brand name.
 *  So this is a common component for all brands which is enabled with a config (login.enabled).
 *  Each brand can make use of other function and events from the JS to login and logout an so on.
 *
 *  To update the config, add config as per following documentation to window.alltid.config
 *  https://github.com/allermedia/se-login-idjs#alltid-configurations
 *
 * (c) Aller Media
 *
 * Version 1.0
 */

/**
 * TODO: Could be updated to white-label structure
 */
import { isProduction } from 'lib/labrador/utils';
import { getUserAuthUrl } from 'lib/utils/getUserAuthUrl';
import getConfig from 'modules/config';
import Script from 'next/script';

const config = getConfig('login') ?? {};
const { brandName, enabled } = config;

const alltIdURL = getUserAuthUrl();
const idjsPath = isProduction ? 'https://cdn.aller.se/login-idjs' : 'https://cdn-stage.aller.se/login-idjs';

const AlltIdAuth = () => {
  if (!enabled || !brandName) return;

  return (
    <Script
      strategy="afterInteractive"
      src={`${idjsPath}/id.min.js`}
      onReady={() => {
        if (window.alltId) {
          window.alltId.config.brand = brandName;
          window.alltId.config.home = alltIdURL;
          window.alltId.run(brandName);
        }
      }}
    />
  );
};

export default AlltIdAuth;
