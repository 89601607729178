/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneLink as ResolvedStandaloneLink } from 'base/components/standalone/Link';

export const Link = ResolvedStandaloneLink;

export type LinkProps = PropsFromComponent<typeof Link>;

export const LinkWith = (extras: DynamicStandaloneExtras): typeof Link => {
    return function Link(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Link');
        return <ResolvedStandaloneLink {...mergeProps({ options: { theme } }, props)} />;
    }
}